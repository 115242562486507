var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ("" + (_vm.isMobile ? 'locale-changer-mobile' : 'locale-changer'))
  }, [_c('v-btn-toggle', {
    staticClass: "mx-auto",
    attrs: {
      "mandatory": "",
      "dense": ""
    },
    model: {
      value: _vm.$root.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$root.$i18n, "locale", $$v);
      },
      expression: "$root.$i18n.locale"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": "no"
    }
  }, [_vm._v(" NO ")]), _c('v-btn', {
    attrs: {
      "value": "en"
    }
  }, [_vm._v(" EN ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }