
import { api } from "@/api/api";
import { ApiGetCustomerDto } from "@/api/generated/Api";
import { authService } from "@/authService";
import CreateProfileStep1 from "@/components/profile/CreateProfileStep1.vue";
import CreateProfileStep2 from "@/components/profile/CreateProfileStep2.vue";
import GooglePlacesAddressFieldGroup from "@/components/shared/GooglePlacesAddressFieldGroup.vue";
import router from "@/router/router";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { flattenObject } from "@/shared/helpers/objectHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import LocaleChanger from "@/shared/localeChanger.vue";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SignupPage",
  components: { GooglePlacesAddressFieldGroup, CreateProfileStep1, LocaleChanger, CreateProfileStep2 },
  setup() {
    const store = useStore<StoreState>();

    const customer = ref<ApiGetCustomerDto>();
    const activeStep = ref({ step: 1, isValid: false });
    const validateFormRef = ref();

    const validateStep = (stepValid: boolean) => {
      activeStep.value.isValid = stepValid;
    };

    const steps = ref([
      { step: 1, isValid: false },
      { step: 2, isValid: false },
    ]);

    const nextStep = () => {
      validateFormRef.value.validate();
      if (!activeStep.value.isValid) {
        return;
      }
      if (activeStep.value.step === steps.value.length) {
        createProfile();
        return;
      }
      activeStep.value = { step: activeStep.value.step + 1, isValid: false };
    };

    const previousStep = () => {
      if (activeStep.value.step === 1) {
        authService.logout();
      }
      validateFormRef.value.validate();
      activeStep.value = { step: activeStep.value.step - 1, isValid: true };
    };

    const updateUser = (updatedCustomer: ApiGetCustomerDto) => {
      customer.value = updatedCustomer;
    };

    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key !== "Enter") {
        return;
      }
      nextStep();
    };

    const createProfile = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!customer.value) {
          return;
        }

        await api.minside.updateCustomerPersonSelfAsync({
          ...flattenObject(customer.value),
          // receiveNewsletter: customer.value.customer.receiveNewsletter,
          // Removed newsletter from markup, AOF wants this to be default true
          receiveNewsletter: true,
        });
        runStoreAction(store, StoreModules.Customer, StoreActions.CustomerActions.SetSignupCompleted);

        if (sessionStorage.getItem("redirectUri") === "/completeSignup") {
          sessionStorage.removeItem("redirectUri");
        }

        router.push({
          path: sessionStorage.getItem("redirectUri") || "/profile",
        });
        sessionStorage.removeItem("redirectUri");
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        customer.value = (await api.minside.getCustomerPersonCurrentAsync()).data;
        customer.value.customer.receiveNewsletter = true;
        window.addEventListener("keydown", keyDownHandler);
      });
    });

    return {
      isMobile,
      customer,
      activeStep,
      steps,
      validateFormRef,
      nextStep,
      validateStep,
      updateUser,
      createProfile,
      previousStep,
    };
  },
});
