var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.customer ? _c('v-container', {
    staticClass: "signup-container"
  }, [_c('LocaleChanger'), !_vm.isMobile ? _c('div', {
    staticClass: "timeLine pa-2"
  }, [_c('v-avatar', {
    attrs: {
      "color": _vm.activeStep.step === 1 ? 'primary' : 'secondary'
    }
  }, [_c('span', {
    staticClass: "white--text text-h5"
  }, [_vm._v("1")])]), _c('v-avatar', {
    attrs: {
      "color": _vm.activeStep.step === 2 ? 'primary' : 'secondary'
    }
  }, [_c('span', {
    staticClass: "white--text text-h5"
  }, [_vm._v("2")])])], 1) : _vm._e(), _c('div', [_c('v-row', {
    staticClass: "pb-4"
  }, [_vm.activeStep.step === 1 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "headerText"
  }, [_vm._v(_vm._s(_vm.$t("profile.signupPage.header")))])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subHeaderText"
  }, [_vm._v(" " + _vm._s(_vm.activeStep.step === 1 ? _vm.$t("profile.signupPage.subHeader") : _vm.$t("profile.signupPage.address")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.activeStep.step === 1 ? _c('CreateProfileStep1', {
    ref: "validateFormRef",
    attrs: {
      "customer": _vm.customer
    },
    on: {
      "updateUser": _vm.updateUser,
      "validate": _vm.validateStep
    }
  }) : _vm._e(), _vm.activeStep.step === 2 ? _c('CreateProfileStep2', {
    ref: "validateFormRef",
    attrs: {
      "customer": _vm.customer
    },
    on: {
      "validate": _vm.validateStep,
      "updateUser": _vm.updateUser
    }
  }) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "navigation pt-4"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.previousStep
    }
  }, [_vm._v(" " + _vm._s(_vm.activeStep.step === 1 ? _vm.$t("profile.signupPage.cancelAndLogOut") : _vm.$t("profile.signupPage.previous")) + " ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(_vm._s(_vm.activeStep.step === _vm.steps.length ? _vm.$t("profile.signupPage.createProfile") : _vm.$t("profile.signupPage.next")) + " ")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }