
import { ApiGetCustomerDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { CustomerAddress } from "@/shared/interfaces/GooglePlaces.interface";
import { defineComponent, PropType, Ref } from "@vue/composition-api";
import GooglePlacesAddressFieldGroup from "../shared/GooglePlacesAddressFieldGroup.vue";

export default defineComponent({
  components: { GooglePlacesAddressFieldGroup },
  props: {
    customer: {
      type: Object as PropType<ApiGetCustomerDto>,
    },
  },
  emits: ["updateAddress", "validate"],
  setup(props, { emit, refs }) {
    const customerValues: Ref<ApiGetCustomerDto | undefined> = useAutoDeepCloneProp<ApiGetCustomerDto | undefined>(
      props,
      "customer"
    );
    const addressValues: CustomerAddress = {
      postAddress1: customerValues.value?.postAddress1,
      postZip: customerValues.value?.postZip,
      postCity: customerValues.value?.postCity,
      postCountry: customerValues.value?.postCountry,
    };

    const updateCustomerAddress = (address: CustomerAddress) => {
      if (!customerValues.value) {
        return;
      }
      customerValues.value.postAddress1 = address.postAddress1;
      customerValues.value.postCity = address.postCity;
      customerValues.value.postZip = address.postZip;
      customerValues.value.postCountry = address.postCountry;
    };

    const validate = () => {
      const isValid = !!getValidatableRef(refs.form)?.validate();
      emit("validate", isValid);
      if (isValid) {
        emit("updateUser", customerValues.value);
      }
    };

    return {
      validate,
      customerValues,
      addressValues,
      updateCustomerAddress,
    };
  },
});
