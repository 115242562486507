<script>
import { isMobile } from "./helpers/displayHelpers";
export default {
  name: "locale-changer",
  setup() {
    return { isMobile };
  },
};
</script>

<template>
  <div :class="`${isMobile ? 'locale-changer-mobile' : 'locale-changer'}`">
    <v-btn-toggle v-model="$root.$i18n.locale" mandatory dense class="mx-auto">
      <v-btn value="no"> NO </v-btn>
      <v-btn value="en"> EN </v-btn>
    </v-btn-toggle>
  </div>
</template>
<style lang="scss">
.locale-changer {
  position: absolute;
  right: 80px;
  top: 3px;
  z-index: 100;
}
.locale-changer-mobile {
  width: 100%;
  position: relative;
  display: flex;
}
</style>
