var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.customerValues ? _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.customer.firstName,
      "headline": _vm.$t('profile.signupPage.firstNameMiddleName'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.customer.lastName,
      "headline": _vm.$t('profile.signupPage.lastName'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.customerValues.email,
      "headline": _vm.$t('profile.signupPage.email'),
      "link": false,
      "captionAbove": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-phone-input', {
    staticClass: "removeDefails",
    attrs: {
      "outlined": "",
      "rules": [_vm.validateNotEmpty],
      "country-icon-mode": "svg",
      "label": ((_vm.$t('profile.signupPage.mobileNumber')) + " *"),
      "countryLabel": ((_vm.$t('profile.signupPage.country')) + " *")
    },
    model: {
      value: _vm.customerValues.mobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "mobileNumber", $$v);
      },
      expression: "customerValues.mobileNumber"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('BaseBirthDateInput', {
    attrs: {
      "birthDate": _vm.customerValues.customer.birthDate,
      "birthYear": _vm.customerValues.customer.birthYear ? _vm.customerValues.customer.birthYear.toString() : '',
      "persistent-placeholder": "",
      "outlined": ""
    },
    on: {
      "dateChanged": _vm.updateBirthDate,
      "yearChanged": _vm.updateBirthYear
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": [{
        label: _vm.$t('profile.signupPage.male'),
        value: 'M'
      }, {
        label: _vm.$t('profile.signupPage.female'),
        value: 'K'
      }],
      "rules": [_vm.validateNotEmpty],
      "label": _vm.$t('profile.signupPage.gender'),
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.customerValues.customer.gender,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues.customer, "gender", $$v);
      },
      expression: "customerValues.customer.gender"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-3 pt-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('profile.signupPage.bankAccountNumber'),
      "hint": _vm.$t('profile.signupPage.bankAccountMessage'),
      "rules": _vm.validateOptionalAccountNumber,
      "type": "text"
    },
    model: {
      value: _vm.customerValues.bankAccountNo,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues, "bankAccountNo", $$v);
      },
      expression: "customerValues.bankAccountNo"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "v1",
      "rules": [_vm.validateNotEmpty],
      "hide-details": "",
      "label": _vm.$t('profile.signupPage.acceptTermsOfUsage')
    },
    model: {
      value: _vm.customerValues.customer.lastApprovedTermsOfUsage,
      callback: function ($$v) {
        _vm.$set(_vm.customerValues.customer, "lastApprovedTermsOfUsage", $$v);
      },
      expression: "customerValues.customer.lastApprovedTermsOfUsage"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "href": "https://www.aof.no/personvernerklaering/",
      "target": "_blank",
      "block": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-link")]), _vm._v(" " + _vm._s(_vm.$t("profile.signupPage.termsOfUsageBtn")) + " ")], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }