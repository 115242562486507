
import { ApiGetCustomerDto } from "@/api/generated/Api";
import BaseIconCard from "@/components/shared/card/BaseIconCard.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty, validateOptionalAccountNumber } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType, Ref } from "@vue/composition-api";
import BaseBirthDateInput from "../shared/date/BaseBirthDateInput.vue";

export default defineComponent({
  name: "CreateProfileStep1",
  components: { BaseIconCard, BaseBirthDateInput },
  props: {
    customer: {
      type: Object as PropType<ApiGetCustomerDto>,
    },
  },
  emits: ["updateUser", "validate"],
  setup(props, { emit, refs }) {
    const customerValues: Ref<ApiGetCustomerDto | undefined> = useAutoDeepCloneProp<ApiGetCustomerDto | undefined>(
      props,
      "customer"
    );

    const validate = () => {
      const isValid = !!getValidatableRef(refs.form)?.validate();
      emit("validate", isValid);
      if (isValid) {
        emit("updateUser", customerValues.value);
      }
    };

    const updateBirthDate = (birthDate: string | undefined) =>
      customerValues.value && (customerValues.value.customer.birthDate = birthDate);

    const updateBirthYear = (year: string) => customerValues.value && (customerValues.value.customer.birthYear = +year);

    return {
      validate,
      validateNotEmpty,
      updateBirthDate,
      customerValues,
      updateBirthYear,
      validateOptionalAccountNumber,
    };
  },
});
